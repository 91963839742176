<template>
	<v-footer color="blue" padless>
		<v-row justify="center" no-gutters>
			<!--  -->
			<v-col class="blue py-4 text-center white--text" cols="12">
				{{ new Date().getFullYear() }} —
				<strong>Superior Pool Resurfacing LLC</strong>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	data: () => ({
		links: ["Home", "About Us", "Free Quote"]
	})
};
</script>